import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as CheckIcon } from '../assets/check-icon.svg';
import API_URL from '../config/contentApi';

const FormBlock = React.forwardRef(({ data, formFields, urlKey, city, address }, ref) => {
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
  const [formSubmitted, setFormSubmitted] = useState(false); // Add form submission state
  const GOOGLE_MAPS_API_KEY = 'AIzaSyBZtMxEo8r95WpsL6FIoSenWHdGuLcyOfM';
  const formContainerRef = useRef(null);
  const mapZoomLevel = 7;

  const onSubmit = async (formData) => {
    const submissionData = { ...formData, key: urlKey, city };

    try {
      const response = await fetch(`https://cors-chat-548058780807.us-central1.run.app/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(submissionData),
      });

      const result = await response.json();
      if (response.ok) {
        // Push submission event to the dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'formSubmission',
          formId: urlKey,
          formCity: city,
          formData: formData,
        });

        // Scroll to the top of the form container
        if (formContainerRef.current) {
          formContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        // Set formSubmitted to true on success
        setFormSubmitted(true);
      } else {
        alert(`Failed to send form: ${result.error}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again later.');
    }
  };

  if (!data) {
    return <div>Loading form block...</div>;
  }

  const { Form, Image } = data;
  const imageUrl = Image && Image[0]?.url;

  // Watch all form values for handling multi-select
  const formValues = watch();

  return (
    <div className="form-block" ref={ref}>
      {/* Left column for contact info and the map */}
      <div className="form-block-left-column">
        {/* Contact info container */}
        <div className="contact-info-container">
          {Form.Phone && <h3>Call us today: <a href={`tel:${Form.Phone}`} className="form-phone-link">{Form.Phone}</a></h3>}
        </div>

        {/* Map or image */}
        {imageUrl ? (
          <img
            src={`${API_URL}${imageUrl}`}
            alt={Image[0]?.alternativeText || 'Form image'}
          />
        ) : (
          <div className="map-container">
            <iframe
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(address)}&zoom=${mapZoomLevel}`}
            ></iframe>
          </div>
        )}
      </div>

      {/* Right column for the form or success message */}
      <div className="form-block-right-column" ref={formContainerRef}>
        {formSubmitted ? (
          // Success Screen
          <div className="success-screen">
            <h2>Thank You!</h2>
            <CheckIcon className="check-icon" />
            <p>We have received your information. Someone from our team will be in contact soon.</p>
          </div>
        ) : (
          // Form
          <div>
            <h2>{Form.Title}</h2>
            {Form.Description && <p>{Form.Description}</p>}

            {/* Form container */}
            <div className="form-container">
              <form onSubmit={handleSubmit(onSubmit)}>
                {formFields.map((field, index) => {
                  if (field.type === 'multi-select') {
                    // Handle multi-select checkboxes
                    return (
                      <fieldset key={index} className="multi-select-fieldset">
                        <legend>{field.label}</legend>
                        {field.options.map((option, optionIndex) => (
                          <label key={optionIndex}>
                            <input
                              type="checkbox"
                              value={option.value}
                              {...register(field.name)}
                              onChange={(e) => {
                                const currentValues = formValues[field.name] || [];
                                if (e.target.checked) {
                                  setValue(field.name, [...currentValues, option.value]);
                                } else {
                                  setValue(
                                    field.name,
                                    currentValues.filter((val) => val !== option.value)
                                  );
                                }
                              }}
                            />
                            {option.label}
                          </label>
                        ))}
                        {errors[field.name] && (
                          <p className="error">{errors[field.name].message}</p>
                        )}
                      </fieldset>
                    );
                  } else {
                    // Handle other field types
                    return (
                      <label key={index}>
                        {field.label}:
                        <input
                          type={field.type}
                          {...register(field.name, field.required ? { required: `${field.label} is required` } : {})}
                        />
                        {errors[field.name] && (
                          <p className="error">{errors[field.name].message}</p>
                        )}
                      </label>
                    );
                  }
                })}

                <button type="submit">SUBMIT</button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default FormBlock;