export const updateFaviconAndTitle = (faviconPath, title) => {
  // Default fallback values
  const defaultFavicon = '/niaLogo.png';
  const defaultTitle = 'Your Offer';

  // Use provided values or fallbacks
  const finalFavicon = faviconPath || defaultFavicon;
  const finalTitle = title || defaultTitle;

  // Update the favicon
  const link =
    document.querySelector("link[rel~='icon']") ||
    document.createElement('link');
  link.rel = 'icon';
  link.href = finalFavicon;
  document.getElementsByTagName('head')[0].appendChild(link);

  // Update the page title
  document.title = finalTitle;
};