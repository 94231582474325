import React from 'react';

function RowSection({ data }) {
  if (!data) {
    return <div>Loading row block...</div>;
  }

  const { Title, Body, Row } = data;

  return (
    <div className="row-block">
      {/* Render the block title if it exists */}
      {Title && <h2 className="row-block-title">{Title}</h2>}

      {/* Render the block body if it exists */}
      {Body && <p className="row-block-body">{Body}</p>}

      <div className="rows">
        {Row && Row.length > 0 ? (
          Row.map((row) => (
            <div key={row.id} className="row">
              {/* Render the row subtitle */}
              {row.Subtitle && <h3 className="row-subtitle">{row.Subtitle}</h3>}

              {/* Render the row body */}
              {row.Body && <p className="row-body">{row.Body}</p>}
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
}

export default RowSection;
