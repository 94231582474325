import React from 'react';
import AddressIcon from '../assets/address-icon.svg';
import PhoneIcon from '../assets/phone-icon.svg';

function Header({ headerImage, address, phone }) {
  // Construct Google Maps URL
  const googleMapsLink = address ? `https://www.google.com/maps?q=${encodeURIComponent(address)}` : '#';

  return (
    <header className="global-header">
      <div className="phone-container">
        {phone && (
          <div className="phone">
            <a href={`tel:${phone}`} aria-label={`Call ${phone}`}>
              <img src={PhoneIcon} className="icon phone-icon" alt="Phone Icon" />
              <span className="text">{phone}</span>
            </a>
          </div>
        )}
      </div>
      <div className="logo">
        <img src={headerImage} alt="Logo" />
      </div>
      <div className="address-container">
        {address && (
          <div className="address">
            <a href={googleMapsLink} target="_blank" rel="noopener noreferrer" aria-label={`View address on Google Maps`}>
              <img src={AddressIcon} className="icon address-icon" alt="Address Icon" />
              <span className="text">{address}</span>
            </a>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;