import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from './globalHeader'; 
import Footer from './globalFooter';
import HeroBanner from './heroBanner';
import ColumnSection from './columnSection';
import RowSection from './rowSection';
import Carousel from './carouselSlider';
import FormBlock from './formBlock';
import Testimonial from './testimonialBlock';
import SingleContent from './singleContent';
import VigPixel from './vigPixel';
import { fetchContent } from '../services/strapiService';
import { updateFaviconAndTitle } from '../utils/metaAssets';
import { contentMap } from '../config/contentMap';

function DynamicPage() {
  const { key } = useParams();
  const [contentData, setContentData] = useState(null);
  const [error, setError] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const [ctaText, setCtaText] = useState(null);
  const formRef = useRef(null);
  const [variables, setVariables] = useState(null);
  const [formFields, setFormFields] = useState([]);

  // Mapping of component types to actual components
  const componentMap = {
    columnSection: ColumnSection,
    rowSection: RowSection,
    carousel: Carousel,
    formBlock: FormBlock,
    testimonial: Testimonial,
    singleContent: SingleContent,
  };

  // Helper function to replace placeholders
  function replacePlaceholders(data, variables) {
    if (typeof data === 'string') {
      return data.replace(/\[([^\]]+)\]/g, (match, p1) => variables[p1] || match);
    } else if (Array.isArray(data)) {
      return data.map(item => replacePlaceholders(item, variables));
    } else if (typeof data === 'object' && data !== null) {
      const newData = {};
      for (const key in data) {
        newData[key] = replacePlaceholders(data[key], variables);
      }
      return newData;
    } else {
      return data;
    }
  }

  useEffect(() => {
    const domain = window.location.hostname;
    const mapping = contentMap[domain];

    if (mapping) {
      // Use values from the content map or defaults
      const faviconPath = mapping.favicon ? `/${mapping.favicon}` : null;
      const title = mapping.metaTitle || null;

      updateFaviconAndTitle(faviconPath, title);
    } else {
      // Use defaults if the domain is not in the content map
      updateFaviconAndTitle(null, null);
    }
  }, []);

  useEffect(() => {
    const domain = window.location.hostname; // Get the domain
    const getAllContent = async () => {
      const mapping = contentMap[domain];
      if (!mapping) {
        setError(`No mapping found for domain: ${domain}`);
        return;
      }

      if (mapping && mapping.fontFamily) {
        // Set font-family dynamically using a CSS variable
        document.documentElement.style.setProperty('--font-family', mapping.fontFamily);
      }
      
      // Get variables for the key
      const variables = mapping.variables && mapping.variables[key];
      if (!variables) {
        setError(`No content found for key: ${key}`);
        return;
      }
      setVariables(variables);
      setHeaderImage(mapping.headerImage);
      setCtaText(mapping.ctaText);

      // Set form fields based on domain-specific mapping or default
      const defaultFormFields = [
        { name: 'firstName', label: 'First name', type: 'text', required: true },
        { name: 'lastName', label: 'Last name', type: 'text', required: true },
        { name: 'email', label: 'Email', type: 'email', required: true },
        { name: 'zip', label: 'Zip code', type: 'text', required: true },
        { name: 'phone', label: 'Phone', type: 'tel', required: true },
      ];
      setFormFields(mapping.formFields || defaultFormFields);

      // Apply color variables from the mapping
      if (mapping.colors) {
        const root = document.documentElement;
        Object.entries(mapping.colors).forEach(([key, value]) => {
          root.style.setProperty(key, value);
        });
      }

      try {
        // Fetch HeroBanner data
        const heroBannerData = await fetchContent(
          mapping.heroBanner.contentType,
          mapping.heroBanner.id
        );
        const processedHeroBannerData = replacePlaceholders(heroBannerData.data, variables);

        // Initialize componentsData
        const componentsData = [];

        // Loop over mapping.components
        for (const component of mapping.components) {
          let contentData = null;

          if (component.type === 'testimonial') {
            if (variables.testimonial) {
              contentData = await fetchContent(
                variables.testimonial.contentType,
                variables.testimonial.id
              );
              contentData = replacePlaceholders(contentData.data, variables);
            } else {
              console.warn('No testimonial data found in variables');
              contentData = null;
            }
          } else {
            // For other components, use the contentType and id from the component
            contentData = await fetchContent(component.contentType, component.id);
            contentData = replacePlaceholders(contentData.data, variables);
          }

          componentsData.push({ type: component.type, data: contentData });
        }

        setContentData({
          heroBanner: processedHeroBannerData,
          components: componentsData,
        });

      } catch (error) {
        setError('Failed to fetch content.');
        console.error('Error fetching content:', error);
      }
    };

    getAllContent();
  }, []);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!contentData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {variables && variables.scriptParams && (
        <VigPixel
          iParam={variables.scriptParams.i}
          sParam={variables.scriptParams.s}
        />
      )}
      <Header headerImage={headerImage} address={variables?.address} phone={variables?.phone}/>

      <HeroBanner
        data={contentData.heroBanner}
        ctaText={ctaText}
        scrollToForm={scrollToForm}
      />

      <div className="page-container">
        {contentData.components.map((component, index) => {
          const ComponentToRender = componentMap[component.type];
          if (!ComponentToRender) {
            console.warn(`No component found for type: ${component.type}`);
            return null;
          }

          const props = {
            data: component.data,
            ...(component.type === 'formBlock' && {
              ref: formRef,
              urlKey: key,
              city: variables.city,
              address: variables.address,
              formFields,
            }),
            ...(component.type === 'carousel' && {
              carouselTitle: component.data.Title,
              slides: component.data.Slide,
            }),
          };

          return <ComponentToRender key={index} {...props} />;
        })}
      </div>
      <Footer />
    </div>
  );
}

export default DynamicPage;