export const contentMap = {
  'www.budget-blinds.us': {
    fontFamily: "'Avenir Next', sans-serif",
    heroBanner: { contentType: 'hero-banners', id: 'glakiitzv1gxrtwnvqmwzunw' },
    components: [
      { type: 'columnSection', contentType: 'column-blocks', id: 'ny6ct80r97iw7i8eavp16zyb' },
      { type: 'columnSection', contentType: 'column-blocks', id: 'k14unhy37i1lnotto2yz3swe' },
      { type: 'carousel', contentType: 'carousels', id: 'lpqjqcl2yk31hfkj2mzw2z8i' },
      { type: 'formBlock', contentType: 'form-blocks', id: 'wcv7rtalcx7kua7uk0jxkd04' },
      { type: 'testimonial' },
    ],
    headerImage: 'bbLogo.jpg',
    colors: {
      '--color-background-light': '#f5f5f5',
      '--color-primary': '#3B5072',
      '--color-secondary': '#6397AD',
      '--color-accent': '#E69B78',
      '--color-cta': '#E69B78',
      '--color-text-light': '#fff',
      '--color-text-dark': '#666',
      '--color-text-primary': '#3B5072',
      '--color-text-secondary': '#555',
      '--color-border': '#ccc',
      '--color-error': 'red',
      '--color-h1': 'black'
    },
    ctaText: 'FREE CONSULTATION',
    variables: {
      'brooklyn': {
        city: 'Brooklyn',
        state: 'NY',
        phone: '917-692-4155',
        address: 'Brooklyn, NY',
        scriptParams: {
          i: '671a4e9ba607612bec51ebf7',
          s: '0b8630ed853e0030edee03c171f54bbc',
        },
        testimonial: { contentType: 'testimonials', id: 'u8u7a5ge99wwhn6pe45yurxl' },
      },
      'edison': {
        city: 'Edison',
        state: 'NJ',
        phone: '609-933-5685',
        address: 'Edison, NJ',
        scriptParams: {
          i: '671a4ecea607612bec51ebf8',
          s: 'cef93ecb0543128cd26f33fb0b229972', 
        },
        testimonial: { contentType: 'testimonials', id: 'f8ooboe9nz3w321bp9zsy1x0' },
      },
      'middletown': {
        city: 'Middletown',
        state: 'CT',
        phone: '860-613-6266',
        address: 'Middletown, CT',
        scriptParams: {
          i: '671a4f06a607612bec51ebf9', 
          s: '563c5211c58207e149fc0f7c90c0bfc9', 
        },
        testimonial: { contentType: 'testimonials', id: 'u4yo5mqcl6iy7pego7zivejz' },
      },      
    },
    gtmId: 'GTM-KX2MV7ZZ',
  },
  'www.tailoredcloset.us': { 
    fontFamily: "'Avenir Next', sans-serif",
    heroBanner: { contentType: 'hero-banners', id: 'w182rnvabkkrlb77zawj2zdz' },
    components: [
      { type: 'columnSection', contentType: 'column-blocks', id: 'bh6krc1tly9eyytdlri9k2s9' },
      { type: 'columnSection', contentType: 'column-blocks', id: 'q0nzwyu0321v3v1sqjyeoi82' },
      { type: 'carousel', contentType: 'carousels', id: 'y0q4key6s3641pgs5xtve0dc' },
      { type: 'formBlock', contentType: 'form-blocks', id: 'lf11i4k0u6vmxc6wd14wekj6' },
      { type: 'testimonial' },
    ],
    headerImage: 'ttLogo.jpg',
    colors: {
      '--color-background-light': '#f5f5f5',
      '--color-primary': '#3d3d3d',
      '--color-secondary': '#2d3a43',
      '--color-accent': '#2d3a43',
      '--color-cta': '#7b96b8',
      '--color-text-light': '#fff',
      '--color-text-dark': '#666',
      '--color-text-primary': '#3d3d3d',
      '--color-text-secondary': '#555',
      '--color-border': '#ccc',
      '--color-error': 'red',
      '--color-h1': 'black'
    },
    ctaText: 'FREE CONSULTATION',
    variables: {
      'carlsbad': {
        city: 'Carlsbad',
        state: 'CA',
        phone: '951-297-9858',
        address: 'Carlsbad, CA',
        scriptParams: {
          i: '671a4e69a607612bec51ebf6',
          s: 'ff905c2e3e943ec74022da6fef222d0e',
        },
        testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'port-charlotte': {
        city: 'Port Charlotte',
        state: 'FL',
        phone: '941-212-2824',
        address: 'Port Charlotte, FL',
        scriptParams: {
          i: '671a4e9ba607612bec51ebf7',
          s: '0b8630ed853e0030edee03c171f54bbc',
        },
        testimonial: { contentType: 'testimonials', id: 'fj50g7849tukqzrmr9esixdz' },
      },
    },
    gtmId: 'GTM-MDRZFGGX',
  },
  'www.expressproslocal.com': { 
    fontFamily: "'Roboto', sans-serif", 
    favicon: 'expFavicon.png',
    metaTitle: 'Express Employment Professionals',
    heroBanner: { contentType: 'hero-banners', id: 'qj7xlew9ph657h0zh903n8kg' },
    components: [
      { type: 'columnSection', contentType: 'column-blocks', id: 'ghw72bzghv433qr9wg83388v' },
      { type: 'singleContent', contentType: 'single-contents', id: 'k4p0tgoiv2y5fzj32mgo0aap' },
      { type: 'columnSection', contentType: 'column-blocks', id: 'p568prg9uv1iiwddzfmj3plt' },
      { type: 'columnSection', contentType: 'column-blocks', id: 'z7cs9nuf8ri4m48e9qorceyn' },
      { type: 'formBlock', contentType: 'form-blocks', id: 'rzjuzssd9amfffbab6e976rh' },
      { type: 'columnSection', contentType: 'column-blocks', id: 'dbmkr9o1ppu0x7wl98yf58l6' },
      { type: 'singleContent', contentType: 'single-contents', id: 'dqzcwwdspe3qoj8k5w0x91f4' },
      { type: 'columnSection', contentType: 'column-blocks', id: 'dtp92k1dhdtnzqfyi7bxcizm' },
      { type: 'rowSection', contentType: 'row-blocks', id: 'ozflckchlujjuvhem34g06r3' },
      { type: 'rowSection', contentType: 'row-blocks', id: 'my3qx54zz399381bc8mwqilm' },
    ],
    headerImage: 'expLogo.png',
    colors: {
      '--color-background-light': '#f5f5f5',
      '--color-primary': '#005288',
      '--color-secondary': '#005288',
      '--color-accent': '#ff7a00',
      '--color-cta': '#7b96b8',
      '--color-text-light': '#fff',
      '--color-text-dark': '#53565a',
      '--color-text-primary': '#005288',
      '--color-text-secondary': '#555',
      '--color-border': '#ccc',
      '--color-error': 'red',
      '--color-h1': 'black'
    },
    ctaText: 'CONTACT US',
    variables: {
      'london-clients': {
        city: 'London',
        state: 'Ontario',
        phone: '(519) 672-7620',
        address: '300 Dundas St London, ON',
        scriptParams: {
          i: '671a4e69a607612bec51ebf6',
          s: 'ff905c2e3e943ec74022da6fef222d0e',
        },
        // testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      },
      'delta-richmont-clients': {
        city: 'Delta/Richmond',
        state: 'BC',
        phone: '(604) 668-5899',
        address: 'Local address',
        scriptParams: {
          i: '671a4e9ba607612bec51ebf7',
          s: '0b8630ed853e0030edee03c171f54bbc',
        },
        // testimonial: { contentType: 'testimonials', id: 'wpyiim5u1y5xt5itulov4zk0' },
      },
    },
    gtmId: 'GTM-TG4RP9X',
    formFields: [
      { name: 'firstName', label: 'First name', type: 'text', required: true },
      { name: 'lastName', label: 'Last name', type: 'text', required: true },
      { name: 'workEmail', label: 'Work email', type: 'email', required: true },
      { name: 'companyName', label: 'Company name', type: 'text', required: true },
      { name: 'phone', label: 'Phone number', type: 'tel', required: true },
      { name: 'services', label: 'I have positions to fill in the following areas (check all that apply):', type: 'multi-select', required: true, options: [
        { label: 'Office services', value: 'Office services' },
        { label: 'Light industrial', value: 'Light industrial' },
        { label: 'Professional', value: 'Professional' },
      ]},
    ],
  },
};